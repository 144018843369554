import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";

const util = {
  data() {
    return {
      cartItemType: {
        CAR: 0,
        TREATMENT: 1,
        INTERPRETER: 2,
        HOTEL: 3,
        SAFE_TRAVEL: 4,
        TOURISM: 5,
        INSURANCE: 6,
        VISA_ASSISTANCE: 7,
      },
      sexTypes: [
        { value: 1, text: "Male" },
        { value: 2, text: "Female" },
      ],
    };
  },
  methods: {
    displayError(error) {
      const msgs = [];
      if (
        !error.response ||
        error.response.status === 500 ||
        typeof error.response.data === "string"
      ) {
        msgs.push("Error: Server Error");
      } else {
        const data = error.response.data;
        for (let key in data) {
          const element = data[key];
          if (Array.isArray(element)) {
            if (typeof element[0] === "string") {
              if (key !== "msg") {
                msgs.push(`${key}: ${element[0]}`);
              } else {
                msgs.push(`${element[0]}`);
              }
            } else if (typeof element[0] === "object") {
              for (const key in element[0]) {
                if (key !== "msg") {
                  msgs.push(`${key}: ${element[0][key][0]}`);
                } else {
                  msgs.push(`${element[0][key][0]}`);
                }
              }
            }
          } else if (typeof element === "object") {
            for (const key in element) {
              if (key !== "msg") {
                msgs.push(`${key}: ${element[key][0]}`);
              } else {
                msgs.push(`${element[key][0]}`);
              }
            }
          } else {
            if (element !== "Invalid page.") {
              msgs.push(element);
            }
          }
        }
      }

      for (let i = 0; i < msgs.length; i++) {
        const msg = msgs[i];
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              text: msg,
              variant: "danger",
            },
          },
          {
            timeout: 5000,
            position: "top-center",
          }
        );
      }
    },
    getCartTypeName(type) {
      switch (type) {
        case this.cartItemType.CAR:
          return "Car Rental";
        case this.cartItemType.INTERPRETER:
          return "Interpreter Service";
        case this.cartItemType.HOTEL:
          return "Hotel";
        case this.cartItemType.SAFE_TRAVEL:
          return "Safe Travel";
        case this.cartItemType.TOURISM:
          return "Tourism";
        case this.cartItemType.TREATMENT:
          return "Treatment";
        case this.cartItemType.VISA_ASSISTANCE:
          return "Visa Assistance";
        case this.cartItemType.INSURANCE:
          return "Insurance";
      }
    },
    truncate(value, maxLength) {
      if (!value) return "";
      if (value.length <= maxLength) return value;
      return value.substring(0, maxLength) + "...";
    },

    displayStringError(error) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            text: error,
            variant: "danger",
          },
        },
        {
          timeout: 5000,
          position: "top-center",
        }
      );
    },
  },
};

export default util;
